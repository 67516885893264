import React from "react";
import ReactGA from 'react-ga';
import { Collapse, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import Smartmockup from "../image/smartmockup.jpg";

import "./Faq.css";

const { Panel } = Collapse;

function Faq() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const { t } = useTranslation();
  return (
    <div>
      <div className="sezione_banner"> </div>
    <div className="section-platform">
      <Row align="middle" justify="center">
        <Col xl={{span:14}} xs={{span:24}} >
            <div className="contain_our_platform">
                <h1 className="title_our_platform_faq"> {t("title_our_platform")} </h1>
                <p className="subtitle_our_platform_faq"
              dangerouslySetInnerHTML={{
                __html: t("paragraph_our_platform", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></p>
             <p className="subtitle_our_platform_faq"
              dangerouslySetInnerHTML={{
                __html: t("paragraph_our_platform1", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></p>
            <p className="subtitle_our_platform_faq"
              dangerouslySetInnerHTML={{
                __html: t("paragraph_our_platform2", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></p>
                </div>
        </Col>
        <Col xl={{span:10}} xs={{span:24}} style={{display:'flex', justifyContent:'center'}}>
            <img className="img-our-platform" src={Smartmockup} alt="smartmockup"/>
        </Col>
    </Row>
    </div>
      <Collapse accordion className="collapse-faq">
        <h1 className="title_our_platform_faq" style={{textAlign: 'center', paddingBottom: '20px'}}> {t("process_internationalization")} </h1>
        <Panel className="panel-header-faq" header={t("faq_1")} key="1">
          <h1 className="panel-text-faq"> {t("faq_1_title")} </h1>
          <div className="panel-text-contain">
            <ul style={{ paddingInlineStart: '20px' }}>
              <li>{t("faq_1_list")}</li>
            </ul>
            <p
              dangerouslySetInnerHTML={{
                __html: t("faq_1_paragraph1", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></p>
            <p dangerouslySetInnerHTML={{
              __html: t("faq_1_paragraph2", {
                interpolation: { escapeValue: false },
              }),
            }}></p>
          </div>
        </Panel>
        <Panel className="panel-header-faq" header={t("faq_2")} key="2">
          <h1 className="panel-text-faq"> {t("faq_2_title")} </h1>
          <div className="panel-text-contain">
          <ul style={{ paddingInlineStart: '20px' }}>
          <li dangerouslySetInnerHTML={{
                __html: t("faq_2_list1", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
          <li dangerouslySetInnerHTML={{
                __html: t("faq_2_list2", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
            </ul>
            <p> {t("faq_2_paragraph1")} </p>
            <p> {t("faq_2_paragraph2")} </p>
            <ul style={{ paddingInlineStart: '20px' }}>
          <li dangerouslySetInnerHTML={{
                __html: t("faq_2_list3", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
          <li dangerouslySetInnerHTML={{
                __html: t("faq_2_list4", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
               <li dangerouslySetInnerHTML={{
                __html: t("faq_2_list5", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
              </ul>
          </div>
        </Panel>
        <Panel className="panel-header-faq" header={t("faq_3")} key="3">
        <h1 className="panel-text-faq"> {t("faq_3_title")} </h1>
        <div className="panel-text-contain">
          <ul style={{ paddingInlineStart: '20px' }}>
          <li dangerouslySetInnerHTML={{
                __html: t("faq_3_list1", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
          <li dangerouslySetInnerHTML={{
                __html: t("faq_3_list2", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
               <li dangerouslySetInnerHTML={{
                __html: t("faq_3_list3", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
            </ul>
            <p dangerouslySetInnerHTML={{
              __html: t("faq_3_paragraph1", {
                interpolation: { escapeValue: false },
              }),
            }}></p>
            <p dangerouslySetInnerHTML={{
              __html: t("faq_3_paragraph2", {
                interpolation: { escapeValue: false },
              }),
            }}></p>
            </div>
        </Panel>
        <Panel className="panel-header-faq" header={t("faq_4")} key="4">
        <h1 className="panel-text-faq"> {t("faq_4_title")} </h1>
        <div className="panel-text-contain">
          <ul style={{ paddingInlineStart: '20px' }}>
          <li dangerouslySetInnerHTML={{
                __html: t("faq_4_list1", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
          <li dangerouslySetInnerHTML={{
                __html: t("faq_4_list2", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
            </ul>
            <p dangerouslySetInnerHTML={{
              __html: t("faq_4_paragraph", {
                interpolation: { escapeValue: false },
              }),
            }}></p>
            </div>
        </Panel>
        <Panel className="panel-header-faq" header={t("faq_5")} key="5">
        <h1 className="panel-text-faq"> {t("faq_5_title")} </h1>
        <div className="panel-text-contain">
          <ul style={{ paddingInlineStart: '20px' }}>
          <li dangerouslySetInnerHTML={{
                __html: t("faq_5_list1", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
          <li dangerouslySetInnerHTML={{
                __html: t("faq_5_list2", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
          <li dangerouslySetInnerHTML={{
                __html: t("faq_5_list3", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
            </ul>
            <p dangerouslySetInnerHTML={{
              __html: t("faq_5_paragraph", {
                interpolation: { escapeValue: false },
              }),
            }}></p>
            </div>
        </Panel>
        <Panel className="panel-header-faq" header={t("faq_6")} key="6">
        <h1 className="panel-text-faq"> {t("faq_6_title")} </h1>
        <div className="panel-text-contain">
          <ul style={{ paddingInlineStart: '20px' }}>
          <li dangerouslySetInnerHTML={{
                __html: t("faq_6_list", {
                  interpolation: { escapeValue: false },
                }),
              }}></li>
            </ul>
            <p dangerouslySetInnerHTML={{
              __html: t("faq_6_paragraph1", {
                interpolation: { escapeValue: false },
              }),
            }}></p>
             <p dangerouslySetInnerHTML={{
              __html: t("faq_6_paragraph2", {
                interpolation: { escapeValue: false },
              }),
            }}></p>
             <p dangerouslySetInnerHTML={{
              __html: t("faq_6_paragraph3", {
                interpolation: { escapeValue: false },
              }),
            }}></p>
             <p dangerouslySetInnerHTML={{
              __html: t("faq_6_paragraph4", {
                interpolation: { escapeValue: false },
              }),
            }}></p>
             <p dangerouslySetInnerHTML={{
              __html: t("faq_6_paragraph5", {
                interpolation: { escapeValue: false },
              }),
            }}></p>
            </div>
        </Panel>
      </Collapse>
    </div>
  );
}
export default Faq;
