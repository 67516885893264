import React from "react";
import { Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";

import expo_smart from "../image/expo_smart.jpg";

import "./SectionCompanies.css";

function SectionCompanies() {
    const { t } = useTranslation();
    
    const scroll = () => {
      var element = document.getElementById("card-list-companies");
     if(element) element.scrollIntoView();
  }

    return (

        <div className="section-div-companies">
            <Row align="middle" >
                <Col xl={{span:12}} xs={{span:24}} style={{display:'flex', justifyContent:'center'}}>
                    <img className="image-div-companies" src={expo_smart} alt="expo_smart" />
                </Col>
                <Col xl={{span:12}} xs={{span:24}}>
                    <div className="contain-text-section-companies">
                    <h1 className="title-section-companies"
              dangerouslySetInnerHTML={{
                __html: t("title_section_companies", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></h1>
                 <p className="subtitle-section-companies"
              dangerouslySetInnerHTML={{
                __html: t("subtitle_section_companies", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></p>
              </div>
                </Col>
            </Row>
        </div>

    );
}
export default SectionCompanies
