import React from "react";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import logoIcham from "../image/icham.png";
import logoIccs from "../image/logoiccs.png";

const CardChamber = () => {
  const { t } = useTranslation();
  return (
    <div className="containCardChamber">
      <div className="col_center sezione_chamber_white">
        <Row justify="center" align="middle">
          <Col align="center" md={{ span: 10 }} xs={{ span: 24 }}>
            <img className="chamber_logo" src={logoIccs} alt="vietnam" />
          </Col>
          <Col md={{ span: 14 }} xs={{ span: 24 }} className="black_text">
            <Row>
              <h2 className="chamber_title">{t("title_iccs")}</h2>
              <p style={{ minHeight: "135px" }}>
                {t("description_chamber_singapore")}
              </p>
            </Row>
            <Row justify="center">
              <a
                href="https://iccs.exportlounge.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  type="submit"
                  size="large"
                  shape="round"
                  className="button-read-more"
                >
                  {t("visit").toUpperCase()}
                </Button>
              </a>
            </Row>
          </Col>
        </Row>
      </div>

      <div className="col_center sezione_chamber_white">
        <Row justify="center" align="middle">
          <Col align="center" md={{ span: 10 }} xs={{ span: 24 }}>
            <img className="chamber_logo" src={logoIcham} alt="vietnam" />
          </Col>
          <Col md={{ span: 14 }} xs={{ span: 24 }} className="black_text">
            <Row>
              <h2 className="chamber_title">{t("title_icham")}</h2>
              <p style={{ minHeight: "135px" }}>{t("text_icham")}</p>
            </Row>
            <Row justify="center">
              <a
                href="https://icham.exportlounge.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  type="submit"
                  size="large"
                  shape="round"
                  className="button-read-more"
                >
                  {t("visit").toUpperCase()}
                </Button>
              </a>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default CardChamber;
