import React, {useEffect} from "react";
import ReactGA from 'react-ga';
import { useTranslation } from "react-i18next";

import "./PrivacyPolicy.css";


function PrivacyPolicy() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    useEffect(() => {
        window.scrollTo(0,0);
      }, []);

    const { t } = useTranslation();

    return (

        <section style={{backgroundColor: '#fff', height:'100%'}}>
           <div className="section-privacypolicy">
            <h1 className="title-privacy-policy"> <strong> {t("privacy_policy")} </strong> </h1>

            <h2 className="subtitle-privacy-policy"> {t("personal_data")} </h2>
            
            <ul>
                <li className="paragraph-privacy-policy"> {t("personal_data1")} </li>
                <li className="paragraph-privacy-policy"> {t("personal_data2")} </li>
                <li className="paragraph-privacy-policy"> {t("personal_data3")} </li>
                <li className="paragraph-privacy-policy"> {t("personal_data4")} </li>
                <p className="paragraph-privacy-policy"> {t("personal_data5")} </p>
            </ul>

            <h2 className="subtitle-privacy-policy"> {t("contact")} </h2>
            <p style={{marginBottom: '0px'}} className="paragraph-privacy-policy"> {t("controller")} </p>
            <p style={{marginBottom: '0px'}} className="paragraph-privacy-policy"> {t("spxlab")} </p>
            <p className="paragraph-privacy-policy"> {t("address")} </p>

            <h2 className="subtitle-privacy-policy"> {t("types_of_data_collected")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_policy1")} </p>
            <p className="paragraph-privacy-policy"> {t("paragraph_policy2")} </p>
            <p className="paragraph-privacy-policy"> {t("paragraph_policy3")} </p>
            <p className="paragraph-privacy-policy"> {t("paragraph_policy4")} </p>

            <h2 className="subtitle-privacy-policy"> {t("mode_and_place_of_processing_of_data")} </h2>
            <h2 className="subtitle-privacy-policy"> {t("processing_method")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_processing_method")} </p>

            <h2 className="subtitle-privacy-policy"> {t("legal_basis_of_the_processing")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_legal_basis_of_the_processing")} </p>
            <ul>
                <li className="paragraph-privacy-policy"> {t("legal_basis_of_the_processing_list1")}  {t("note_legal_of_the_processing_list1")} </li>
                <li className="paragraph-privacy-policy"> {t("legal_basis_of_the_processing_list2")} </li>
                <li className="paragraph-privacy-policy"> {t("legal_basis_of_the_processing_list3")} </li>
                <li className="paragraph-privacy-policy"> {t("legal_basis_of_the_processing_list4")} </li>
                <p className="paragraph-privacy-policy"> {t("paragraph_legal_basis_of_the_processing1")} </p>
            </ul>

            <h2 className="subtitle-privacy-policy"> {t("place_of_treatment")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_place_of_treatment1")} </p>
            <p className="paragraph-privacy-policy"> {t("paragraph_place_of_treatment2")} </p>
            <p className="paragraph-privacy-policy"> {t("paragraph_place_of_treatment3")} </p>

            <h2 className="subtitle-privacy-policy"> {t("retention_period")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_retention_period")} </p>
            <ul>
                <li className="paragraph-privacy-policy"> {t("retention_period_list1")} </li>
                <li className="paragraph-privacy-policy"> {t("retention_period_list2")} </li>
                <p className="paragraph-privacy-policy"> {t("paragraph_retention_period_list2")} </p>
            </ul>

            <h2 className="subtitle-privacy-policy"> {t("purposes_of_the_processing")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_purposes_of_the_processing1")} </p>
            <p className="paragraph-privacy-policy"> {t("paragraph_purposes_of_the_processing2")} </p>

            <h2 className="subtitle-privacy-policy"> {t("details_on_the_processing")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_details_on_the_processing")} </p>
            <ul>
                <li className="paragraph-privacy-policy-list"> {t("contact_the_user")} </li>
                <p className="paragraph-privacy-policy"> {t("paragraph_contact_the_user")} </p>
            </ul>

            <ul>
                <li className="paragraph-privacy-policy-list"> {t("protection_from_spam")} </li>
                <p className="paragraph-privacy-policy"> {t("paragraph_protection_from_spam")} </p>
            </ul>

            <ul>
                <li className="paragraph-privacy-policy-list"> {t("statistics")} </li>
                <p className="paragraph-privacy-policy"> {t("paragraph_statistics")} </p>
            </ul>

            <ul>
                <li className="paragraph-privacy-policy-list"> {t("platform_and_hosting_services")} </li>
                <p className="paragraph-privacy-policy"> {t("paragraph_platform_and_hosting_services")} </p>
            </ul>

            <ul>
                <li className="paragraph-privacy-policy-list"> {t("displaying_content_from_external_platforms")} </li>
                <p className="paragraph-privacy-policy"> {t("paragraph_displaying_content_from_external_platforms")} </p>
            </ul>

            <h2 className="subtitle-privacy-policy"> {t("user_rights")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_user_rights")} </p>
            <ul>
                <li className="paragraph-privacy-policy"> {t("user_rights_list1")} </li>
                <li className="paragraph-privacy-policy"> {t("user_rights_list2")} </li>
                <li className="paragraph-privacy-policy"> {t("user_rights_list3")} </li>
                <li className="paragraph-privacy-policy"> {t("user_rights_list4")} </li>
                <li className="paragraph-privacy-policy"> {t("user_rights_list5")} </li>
                <li className="paragraph-privacy-policy"> {t("user_rights_list6")} </li>
                <li className="paragraph-privacy-policy"> {t("user_rights_list7")} </li>
                <li className="paragraph-privacy-policy"> {t("user_rights_list8")} </li>
            </ul>

            <h2 className="subtitle-privacy-policy"> {t("details_on_the_right_of_opposition")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_details_on_the_right_of_opposition1")} </p>
            <p className="paragraph-privacy-policy"> {t("paragraph_details_on_the_right_of_opposition2")} </p>

            <h2 className="subtitle-privacy-policy"> {t("how_to_exercise_the_rights")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_how_to_exercise_the_rights")} </p>

            <h2 className="subtitle-privacy-policy"> {t("further_information")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_further_information")} </p>

            <h2 className="subtitle-privacy-policy"> {t("specific_information")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_specific_information")} </p>

            <h2 className="subtitle-privacy-policy"> {t("system_logs_and_maintenance")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_logs_and_maintenance")} </p>

            <h2 className="subtitle-privacy-policy"> {t("response_to_do_not_track_requests")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_response_to_do_not_track_requests")} </p>

            <h2 className="subtitle-privacy-policy"> {t("changes_to_privacy_policy")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_changes_to_privacy_policy1")} </p>
            <p className="paragraph-privacy-policy"> {t("paragraph_changes_to_privacy_policy2")} </p>

            <h2 className="subtitle-privacy-policy"> {t("definitions_and_legal_references")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_definitions_and_legal_references")} </p>

            <h2 className="subtitle-privacy-policy"> {t("usage_data")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_usage_data")} </p>

            <h2 className="subtitle-privacy-policy"> {t("user")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_user")} </p>

            <h2 className="subtitle-privacy-policy"> {t("interested")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_interested")} </p>

            <h2 className="subtitle-privacy-policy"> {t("data_processor")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_data_processor")} </p>

            <h2 className="subtitle-privacy-policy"> {t("data_controller")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_data_controller")} </p>

            <h2 className="subtitle-privacy-policy"> {t("this_application")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_this_application")} </p>

            <h2 className="subtitle-privacy-policy"> {t("european_union")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_european_union")} </p>

            <h2 className="subtitle-privacy-policy"> {t("cookie")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_cookie")} </p>

            <h2 className="subtitle-privacy-policy"> {t("legal_reference")} </h2>
            <p className="paragraph-privacy-policy"> {t("paragraph_legal_reference")} </p>



            </div>

           </section>

    );
}
export default PrivacyPolicy;
