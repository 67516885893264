import React from "react";
import { Row, Col} from "antd";
import { ReactComponent as Quotes } from '../icon/quotes.svg';
import { useTranslation } from "react-i18next";

import tgcom24 from '../image/tgcom24.png';
import affariitaliani from '../image/affariitaliani.jpg';
import bollettino from '../image/bollettino.png';

import "./AboutUs.css";

function AboutUs() {
    const { t } = useTranslation();


    return (

        <div className="section-about-us">
            <h1 className="title-section-about-us"
                dangerouslySetInnerHTML={{
                    __html: t("title_section_about_us", {
                        interpolation: { escapeValue: false },
                    }),
                }}
            ></h1>
            <Row>
                <Col span={24}>
                    <div className="contain-card-icon1">
                        <Quotes className="icon-card-about-us" />
                    </div>
                    <div className="contain-text-about-us">
                        <p className="p-about-us"> {t("text_affari_italiani")} </p>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <img className="img-about-us" src={affariitaliani} />
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <div className="contain-card-icon2" style={{}}>
                        <Quotes className="icon-card-about-us" />
                    </div>
                    <div className="contain-text-about-us">
                        <p className="p-about-us"> {t("text_tgcom")} </p>

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <img className="img-about-us" src={tgcom24} />
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <div className="contain-card-icon1">
                        <Quotes className="icon-card-about-us" />
                    </div>
                    <div className="contain-text-about-us">
                        <p className="p-about-us"> {t('text_bollettino')} </p>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <img className="img-about-us" src={bollettino} />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
export default AboutUs;
