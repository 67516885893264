import React from "react";
import { Row, Col} from "antd";
import { useTranslation } from "react-i18next";

import LogoIcham from '../image/logo-icham.png';
import LogoLegal from '../image/logo-legalmondo.png';

import "./Partner.css";

function AboutUs() {
    const { t } = useTranslation();

    return (

        <div className="section_partner">
            <h1 className="title-section-about-us"
                dangerouslySetInnerHTML={{
                    __html: t("title_section_partner", {
                        interpolation: { escapeValue: false },
                    }),
                }}
            ></h1>

            <Row justify="center">
                <Col align="center" xl={{ span: 12 }} md={{ span: 12 }} lg={{span:10}} xs={{ span: 24 }}>
                   <a href="https://www.legalmondo.com/it/" target="_blank"> <img className="img-partner" src={LogoLegal} /> </a>
                </Col>

                <Col align="center" xl={{ span: 12, offset:0}} md={{ span: 12}} lg={{span:10, offset: 2}} xs={{ span: 24 }}>
                <a href="https://icham.org/" target="_blank">  <img className="img-partner" src={LogoIcham} /> </a>
                </Col>
            </Row>

        </div>

    );
}
export default AboutUs;
