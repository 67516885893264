import React from "react";
import { useTranslation } from "react-i18next";

import "./SectionVideo.css";
import CardChamber from "./CardChamber";

function SectionParallax() {
  const { t } = useTranslation();

  return (
    <div className="sezione-video">
      <div className="contain-parallax-text" style={{ textAlign: "center" }}>
        <h1 className="h1-parallax">{t("subtitle_parallax")}</h1>
        <p className="p-parallax">{t("title_parallax")}</p>
      </div>
      <CardChamber />
    </div>
  );
}
export default SectionParallax;
