import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Ita from '../image/ita.png';
import Eng from '../image/eng.png';
import logoexpo from "../image/logo-536x121.png";
import { useTranslation } from "react-i18next";
import { ReactComponent as Whatsapp } from '../icon/whatsapp.svg';

import "./Header.css";

function Header() {
  const { t } = useTranslation();

  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const changeLanguage = (e, lng) => {
    e.preventDefault();
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 1000px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  return (
    <header className="Header">
      <Link to="/">
      <img src={logoexpo} className="logo-header" alt="logo" />
      </Link>
      {(!isSmallScreen || isNavVisible) && (
        <nav className="Nav">
         {/* <a href="/" className="nav-menu-voice">
            {t("menu_chambers")}
      </a> */}
          <Link to="/pricing" className="nav-menu-voice">
            {t("menu_pricing")}
          </Link>
          <Link to="/faq" className="nav-menu-voice">
            {t("menu_faq")}
          </Link>
          <Link to="/contacts" className="nav-menu-voice">
            {t("menu_contact")}
          </Link>
          <a href="https://wa.me/393487910087" className="nav-menu-voice"> <Whatsapp style={{stroke:'#a72224', width:'35px'}}/> </a> 
          <img src={Ita} style={{width:'50px', height:'auto', padding:'10px 10px', cursor:'pointer'}} type="primary" onClick={(e) => changeLanguage(e, 'it-IT')}/>
          <img src={Eng} style={{ width:'50px', height:'auto', padding:'10px 10px', cursor:'pointer'}} type="primary" onClick={(e) => changeLanguage(e, 'en-EN')}/> 
        </nav>
      )}
      <MenuOutlined onClick={toggleNav} className="Burger" />
    </header>
  );
}
export default Header;
