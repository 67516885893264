import React from "react";
import { Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import preview from "../image/preview.png";

import { ReactComponent as StandVirtuale } from "../icon/standvirtuale.svg";
import { ReactComponent as StanzeEmozionali } from "../icon/stanzeemozionali.svg";
import { ReactComponent as Continuity } from "../icon/continuita.svg";
import { ReactComponent as Business } from "../icon/bsm.svg";
import { ReactComponent as Analytics } from "../icon/analytics.svg";

import "./CardList.css";

function CardList() {
  const { t } = useTranslation();

  return (
    <div id="card-list-companies" className="section-icon-card">
      <h1
        className="title-section-icon-card"
        dangerouslySetInnerHTML={{
          __html: t("title_section_card", {
            interpolation: { escapeValue: false },
          }),
        }}
      ></h1>
      {/* Primo */}
      <Row align="middle">
        <Col xl={{ span: 1 }} md={{ span: 2 }} xs={{ span: 3 }}>
          <StandVirtuale className="icon-card-list" />
        </Col>
        <Col xl={{ span: 23 }} md={{ span: 22 }} xs={{ span: 19 }}>
          <p className="p-card-title">
            <b>{t("title_card1")}</b>
          </p>
        </Col>
      </Row>
      <Row align="middle" className="row-section-card">
        <Col
          xl={{ offset: 1, span: 23 }}
          md={{ offset: 2, span: 22 }}
          xs={{ offset: 3, span: 19 }}
        >
          <p
            className="p-card-list"
            dangerouslySetInnerHTML={{
              __html: t("subtitle_card1", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></p>
        </Col>
      </Row>

      {/* Secondo */}
      <Row align="middle">
        <Col xl={{ span: 1 }} md={{ span: 2 }} xs={{ span: 3 }}>
          <StanzeEmozionali className="icon-card-list" />
        </Col>
        <Col xl={{ span: 23 }} md={{ span: 22 }} xs={{ span: 19 }}>
          <p className="p-card-title">
            <b> {t("title_card2")} </b>
          </p>
        </Col>
      </Row>
      <Row align="middle" className="row-section-card">
        <Col
          xl={{ offset: 1, span: 23 }}
          md={{ offset: 2, span: 22 }}
          xs={{ offset: 3, span: 19 }}
        >
          <p
            className="p-card-list"
            dangerouslySetInnerHTML={{
              __html: t("subtitle_card2", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></p>
        </Col>
      </Row>

      {/* Terzo */}
      <Row align="middle">
        <Col xl={{ span: 1 }} md={{ span: 2 }} xs={{ span: 3 }}>
          <Business className="icon-card-list" />
        </Col>
        <Col xl={{ span: 23 }} md={{ span: 22 }} xs={{ span: 19 }}>
          <p className="p-card-title">
            <b> {t("title_card3")} </b>
          </p>
        </Col>
      </Row>
      <Row align="middle" className="row-section-card">
        <Col
          xl={{ offset: 1, span: 23 }}
          md={{ offset: 2, span: 22 }}
          xs={{ offset: 3, span: 19 }}
        >
          <p
            className="p-card-list"
            dangerouslySetInnerHTML={{
              __html: t("subtitle_card3", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></p>
        </Col>
      </Row>

      {/* Quarto */}
      <Row align="middle">
        <Col xl={{ span: 1 }} md={{ span: 2 }} xs={{ span: 3 }}>
          <Continuity className="icon-card-list" />
        </Col>
        <Col xl={{ span: 23 }} md={{ span: 22 }} xs={{ span: 19 }}>
          <p className="p-card-title">
            <b> {t("title_card5")} </b>
          </p>
        </Col>
      </Row>
      <Row align="middle" className="row-section-card">
        <Col
          xl={{ offset: 1, span: 23 }}
          md={{ offset: 2, span: 22 }}
          xs={{ offset: 3, span: 19 }}
        >
          <p
            className="p-card-list"
            dangerouslySetInnerHTML={{
              __html: t("subtitle_card5", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></p>
        </Col>
      </Row>

      {/* Quinto */}
      <Row align="middle">
        <Col xl={{ span: 1 }} md={{ span: 2 }} xs={{ span: 3 }}>
          <Analytics className="icon-card-list" />
        </Col>
        <Col xl={{ span: 23 }} md={{ span: 22 }} xs={{ span: 19 }}>
          <p className="p-card-title">
            <b> {t("title_card4")} </b>
          </p>
        </Col>
      </Row>
      <Row
        align="middle"
        className="row-section-card"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col
          xl={{ offset: 1, span: 23 }}
          md={{ offset: 2, span: 22 }}
          xs={{ offset: 3, span: 19 }}
        >
          <p
            className="p-card-list"
            dangerouslySetInnerHTML={{
              __html: t("subtitle_card4", {
                interpolation: { escapeValue: false },
              }),
            }}
          ></p>
        </Col>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center", width: "80%" }}
        >
          <ReactPlayer
            controls="true"
            width="100%"
            height="100%"
            className="react-player"
            url="https://vimeo.com/463471970/c9b7ab828e"
          />
        </div>
      </Row>
    </div>
  );
}
export default CardList;
