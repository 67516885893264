import React from "react";
import { Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";

import { ReactComponent as Energy } from '../icon/idea.svg';
import { ReactComponent as Consumer } from '../icon/shopping-bag.svg';
import { ReactComponent as Info } from '../icon/info.svg';
import { ReactComponent as Finance } from '../icon/cash.svg';
import { ReactComponent as Materials } from '../icon/drag-n-drop.svg';
import { ReactComponent as ConsumerStample} from '../icon/shopping-cart.svg';
import { ReactComponent as RealEstate} from '../icon/home.svg';
import { ReactComponent as Communication} from '../icon/like.svg';
import { ReactComponent as Industry} from '../icon/setting.svg';
import { ReactComponent as Utilities} from '../icon/users.svg';
import { ReactComponent as Health} from '../icon/hearth.svg';
import { ReactComponent as Governemente} from '../icon/globe.svg';
import "./SectorList.css";

function SectorList() {
    const { t } = useTranslation();


    return (

        <div className="sezione-settori" style={{ }}>
            <h1 className="title_sector"
                dangerouslySetInnerHTML={{
                    __html: t("title_section_sector", {
                        interpolation: { escapeValue: false },
                    }),
                }}
            ></h1>
            <Row align="middle" justify="center">
                <Col xl={{span:1, offset:0}} xs={{offset:2, span:3}}>
                    <Energy className="icon-sector" />
                </Col>
                <Col xl={{span:4}} xs={{span:19, offset:0}}>
                    <p className="name_sector"> {t("sector1")} </p>
                </Col>
                <Col xl={{span:1, offset:1}} xs={{offset:2, span:3}}>
                    <Consumer className="icon-sector" />
                </Col>
                <Col xl={{span:4}} xs={{span:19, offset:0}}>
                    <p className="name_sector"> {t("sector2")} </p>
                </Col>
                <Col xl={{span:1, offset:1}} xs={{offset:2, span:3}}>
                    <Info className="icon-sector" />
                </Col>
                <Col xl={{span:4}} xs={{span:19, offset:0}}>
                    <p className="name_sector"> {t("sector3")} </p>
                </Col>
                <Col xl={{span:1, offset:1}} xs={{offset:2, span:3}}>
                    <Finance className="icon-sector" />
                </Col>
                <Col xl={{span:4}} xs={{span:19}}>
                    <p className="name_sector"> {t("sector4")} </p>
                </Col>
            </Row>

            <Row align="middle" justify="center">
                <Col xl={{span:1, offset:0}} xs={{offset:2, span:3}}>
                    <Materials className="icon-sector" />
                </Col>
                <Col xl={{span:4}} xs={{span:19}}>
                    <p className="name_sector"> {t("sector5")} </p>
                </Col>
                <Col xl={{span:1, offset:1}} xs={{offset:2, span:3}}>
                    <ConsumerStample className="icon-sector" />
                </Col>
                <Col xl={{span:4}} xs={{span:19}}>
                    <p className="name_sector"> {t("sector6")} </p>
                </Col>
                <Col xl={{span:1, offset:1}} xs={{offset:2, span:3}}>
                    <RealEstate className="icon-sector" />
                </Col>
                <Col xl={{span:4}} xs={{span:19}}>
                    <p className="name_sector"> {t("sector7")} </p>
                </Col>
                <Col xl={{span:1, offset:1}} xs={{offset:2, span:3}}>
                    <Communication className="icon-sector" />
                </Col>
                <Col xl={{span:4}} xs={{span:19}}>
                    <p className="name_sector"> {t("sector8")} </p>
                </Col>
            </Row>

            <Row align="middle" justify="center">
                <Col xl={{span:1, offset:0}} xs={{offset:2, span:3}}>
                    <Industry className="icon-sector" />
                </Col>
                <Col xl={{span:4}} xs={{span:19}}>
                    <p className="name_sector"> {t("sector9")} </p>
                </Col>
                <Col xl={{span:1, offset:1}} xs={{offset:2, span:3}}>
                    <Utilities className="icon-sector" />
                </Col>
                <Col xl={{span:4}} xs={{span:19}}>
                    <p className="name_sector"> {t("sector10")} </p>
                </Col>
                <Col xl={{span:1, offset:1}} xs={{offset:2, span:3}}>
                    <Health className="icon-sector" />
                </Col>
                <Col xl={{span:4}} xs={{span:19}}>
                    <p className="name_sector"> {t("sector11")} </p>
                </Col>
                <Col xl={{span:1, offset:1}} xs={{offset:2, span:3}}>
                    <Governemente className="icon-sector" />
                </Col>
                <Col xl={{span:4}} xs={{span:19}}>
                    <p className="name_sector"> {t("sector12")} </p>
                </Col>
            </Row>

        </div>

    );
}
export default SectorList;
