import React from "react";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'
import ProgressBar from "../components/ProgressBar";
import SliderCarousel from "../components/SliderCarousel";
import SectionCompanies from "../components/SectionCompanies";
import SectorList from "../components/SectorList";
import CardList from "../components/CardList";
import SectionVideo from "../components/SectionVideo";
import AboutUs from "../components/AboutUs";
import Partner from "../components/Partner";
import ProductSpx from "../components/ProductSpx";

function Home() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  const tagManagerArgs = {
    gtmId: 'GTM-MGPQ729'
  }
  TagManager.initialize(tagManagerArgs);

  ReactPixel.init('3315500525205123');
  ReactPixel.pageView();
  return (
    <>
      <SliderCarousel />
      <ProgressBar />
      <SectionCompanies />
      <SectorList />
      <CardList />
      <SectionVideo />
      <AboutUs />
      <Partner />
      <ProductSpx />
    </>
  );
}
export default Home;
