import React from "react";
import { Link } from "react-router-dom";
import logoexpo from "../image/logo-536x121.png";
import dayjs from "dayjs";

import { ReactComponent as Position } from "../icon/map-position.svg";
import { ReactComponent as Phone } from "../icon/phone.svg";
import { ReactComponent as Mail } from "../icon/mail.svg";
import { ReactComponent as Facebook } from "../icon/facebook.svg";
import { ReactComponent as Linkedin } from "../icon/linkedin.svg";
import { ReactComponent as Whatsapp } from "../icon/whatsapp.svg";
import { useTranslation } from "react-i18next";

import "./Footer.css";
import { Row, Col } from "antd";

function AboutUs() {
  const { t } = useTranslation();
  const current_year = dayjs().year();

  return (
    <div className="section-footer">
      <Row align="middle" justify="center">
        <Col
          align="left"
          xl={{ span: 4, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 4 }}
          md={{ span: 24 }}
          sm={{ span: 24, offset: 0 }}
          style={{ margin: "30px 0px" }}
        >
          <img className="logo-footer" src={logoexpo} alt="logoexpo" />
        </Col>
        <Col
          xl={{ span: 16, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 9, offset: 1 }}
          md={{ span: 12 }}
          sm={{ span: 24, offset: 0 }}
          align="center"
          style={{ margin: "30px 0px" }}
        >
          <a href="https://www.facebook.com/exportlounge" target="_blank">
            <Facebook className="icon-social" />
          </a>
          <a
            href="https://www.linkedin.com/showcase/exportlounge/"
            target="_blank"
          >
            <Linkedin className="icon-social" />
          </a>
          <p style={{ marginBottom: "0px" }}>
            {t("export")}
            <b>{t("lounge")}</b> {t("footer_text")}
            <a className="link-footer" href="https://www.spxlab.com/">
              <b> {t("spxlab").concat(" ")}</b>
            </a>
            © {current_year}
          </p>
          <Link className="scrollToTop link-footer" to="/privacypolicy">
            <b> {t("privacy_policy")} </b>
          </Link>
        </Col>
        <Col
          xl={{ span: 4, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 9, offset: 1 }}
          md={{ span: 12 }}
          sm={{ span: 24, offset: 0 }}
          align="left"
          style={{ margin: "30px 0px" }}
        >
          <p className="footer-contact" style={{ textAlign: "left" }}>
            <b> {t("contacts")} </b>
          </p>
          <p className="p-footer">
            <Position className="icon-contact-footer" /> {t("address_spx")}
          </p>
          <p className="p-footer">
            <Phone className="icon-contact-footer" /> {t("phone_spx")}
          </p>
          <p className="p-footer">
            <Whatsapp className="icon-contact-footer" />
            <a style={{ color: "#000" }} href="https://wa.me/393403885585">
              {t("whatsapp_spx")}
            </a>
          </p>
          <p className="p-footer">
            <Mail className="icon-contact-footer" /> {t("mail_spx")}
          </p>
        </Col>
      </Row>
    </div>
  );
}
export default AboutUs;
