import axios from 'axios';
import React from "react";
import { notification } from 'antd';
import { Trans } from 'react-i18next'

import cfg from './config/expo_config.json';

const api = axios.create({
    baseURL: cfg.api.url
});

api.interceptors.response.use(
    (response) => { return response },
    function (error) {
        //const originalRequest = error.config;
        if (error.response) {
            const response = error.response;
            console.error("Intercettato errore", error.response.data);
            if(!parseError(response))
                return Promise.reject(error);
        } else if (error.request) {
            console.error("error.request", error.request);
            return Promise.reject(error);
        } else {
            console.error('Error', error.message);
            return Promise.reject(error);
        }
        console.log("error.config", error.config);
    });

const parseError = response => {
    if (response.status === 400) { //Unhautorized
        return manageBadRequesr(response.data);
    } else
    if (response.status === 401) { //Unhautorized
        return manageUnhautorized(response.data);
    } else {
        //manageError("Errore intercettato!");
        console.log("Error ", response.data);
        return false;
    }
}

const manageBadRequesr = errorBody => {
    if (errorBody && errorBody.eCode) {
        notification.error({
            key: 'axiosNotifyError',
            message: 'Error',
            description: <Trans>{`error_${errorBody.eCode}`}</Trans>,
            placement: 'topRight',
            duration: 0
        });
        return true;
    }
    return false;
}

const manageUnhautorized = errorBody => {
    if (errorBody && errorBody.eCode) {
        notification.error({
            key: 'axiosNotifyError',
            message: 'Error',
            description: <Trans>{`error_${errorBody.eCode}`}</Trans>,
            placement: 'topRight',
            duration: 0
        });
        return true;
    }
    return false;
}

const createFile = (idCompany, itemData, token, callBackForUploadPercentage,fileType) => api.post(`/companies/${idCompany}/showcase-items/${fileType}`, itemData, {
    headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": 'multipart/form-data'
    },
    onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (callBackForUploadPercentage) callBackForUploadPercentage(percentCompleted);
    }
})

const authHeader = (token) => {
    return token ? { Authorization: `Bearer ${token}` } : null;
}


const contact = {
    post: (contactData) => api.post('/contacts', contactData),
}


export default {
    contact
};
