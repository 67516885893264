import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Row, Col, Switch, Typography } from "antd";
import { ReactComponent as Check } from "../icon/check.svg";
import { ReactComponent as CheckOut } from "../icon/x.svg";
import { useTranslation } from "react-i18next";

import "./Pricing.css";

const { Title } = Typography;

function Pricing() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [switchSelected, setSwitchSelected] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSwitchClick = (checked) => {
    setSwitchSelected(checked);
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="sezione_banner"></div>
      <div className="sezione-grey" style={{textAlign:'center'}}>
        <h1 className="title_section_pricing"> {t("access_plans")} </h1>
        <Title level={2}>{t('message_free_trial_period')}</Title>

        <div className="sezione-piani-accesso">
          <div className="p-abbonamento">
            <span
              id="month"
              className="span-pricing"
              style={{ color: switchSelected ? "grey" : "black" }}
              onClick={() => setSwitchSelected(false)}
            >
              {t("subscription_month")}
            </span>
            <Switch
              className="switch-pricing"
              onClick={handleSwitchClick}
              checked={switchSelected}
            />
            <span
              id="year"
              className="span-pricing"
              style={{ color: switchSelected ? "black" : "grey" }}
              onClick={() => setSwitchSelected(true)}
            >
              {t("subscription_year")}
            </span>
          </div>
        </div>

        {/* ABBONAMENTO MENSILE */}
        <div id="abbonamento">
          <Row align="middle" justify="center" className="row-pricing">
            <Col
              xxl={{ span: 7 }}
              xl={{ span: 7 }}
              className="col-card-pricing"
              style={{
                backgroundColor: "#be6767",
                border: "2px solid #be6767",
              }}
            >
              <div justify="center" style={{ color: "#fff" }}>
                <div>
                  <p className="titles_card">
                    <b>Premium</b>
                  </p>
                  <hr
                    style={{
                      width: "80px",
                      height: "2px",
                      borderWidth: "0",
                      color: "gray",
                      backgroundColor: "#fff",
                    }}
                  ></hr>
                  <p className="p-price-card">
                    <b>€ {switchSelected ? 390 : 39}</b>
                  </p>
                  <p>
                    {switchSelected
                      ? t("annual_price_card")
                      : t("per_month_price_card")}
                    *
                  </p>
                </div>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_view_company")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_b2b")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subsription_basic_showcase")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <CheckOut className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso-checkout">
                  {t("subscription_advanced_showcas")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <CheckOut className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso-checkout">
                  {t("subscription_business_matching_score")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <CheckOut className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso-checkout">
                  {t("subscription_view_products_showcase")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <CheckOut className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso-checkout">
                  {t("subscription_direct_contact")}
                </span>
              </div>
            </Col>

            <Col
              xxl={{ span: 7, offset: 1 }}
              xl={{ span: 7, offset: 1 }}
              className="col-card-pricing"
              style={{
                backgroundColor: "#bf3f48",
                border: "2px solid #bf3f48",
              }}
            >
              <div justify="center" style={{ color: "#fff" }}>
                <div>
                  <p className="titles_card">
                    <b>Business</b>
                  </p>
                  <hr
                    style={{
                      width: "80px",
                      height: "2px",
                      borderWidth: "0",
                      color: "gray",
                      backgroundColor: "#fff",
                    }}
                  ></hr>
                  <p className="p-price-card">
                  <b>€ {switchSelected ? 490 : 49}</b>
                  </p>
                  <p>
                    {switchSelected
                      ? t("annual_price_card")
                      : t("per_month_price_card")}
                    *
                  </p>
                </div>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_view_company")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_b2b")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subsription_basic_showcase")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_advanced_showcas")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_business_matching_score")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_view_products_showcase")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <CheckOut className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso-checkout">
                  {t("subscription_direct_contact")}
                </span>
              </div>
            </Col>

            <Col
              xxl={{ span: 7, offset: 1 }}
              xl={{ span: 7, offset: 1 }}
              className="col-card-pricing"
              style={{
                backgroundColor: "#be1522",
                border: "2px solid #be1522",
              }}
            >
              <div justify="center" style={{ color: "#fff" }}>
                <div>
                  <p className="titles_card">
                    <b>Executive</b>
                  </p>
                  <hr
                    style={{
                      width: "80px",
                      height: "2px",
                      borderWidth: "0",
                      color: "gray",
                      backgroundColor: "#fff",
                    }}
                  ></hr>
                  <p className="p-price-card">
                  <b>€ {switchSelected ? 590 : 59}</b>
                  </p>
                  <p>
                    {switchSelected
                      ? t("annual_price_card")
                      : t("per_month_price_card")}
                    *
                  </p>
                </div>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_view_company")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_b2b")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subsription_basic_showcase")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_advanced_showcas")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_business_matching_score")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_view_products_showcase")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_direct_contact")}
                </span>
              </div>
            </Col>
          </Row>
        </div>

        {/* ABBONAMENTO ANNUALE */}
        {/*
        <div id="abbonamento-annuale" style={{ display: "none" }}>
          <Row align="middle" justify="center" className="row-pricing">
            <Col
              xxl={{ span: 7 }}
              xl={{ span: 7 }}
              className="col-card-pricing"
              style={{
                backgroundColor: "#be6767",
                border: "2px solid #be6767",
              }}
            >
              <div justify="center" style={{ color: "#fff" }}>
                <div>
                  <p className="titles_card">
                    
                    <b>Premium</b>
                  </p>
                  <hr
                    style={{
                      width: "80px",
                      height: "2px",
                      borderWidth: "0",
                      color: "gray",
                      backgroundColor: "#fff",
                    }}
                  ></hr>
                  <p className="p-price-card">
                    
                    <b>€390</b>
                  </p>
                  <p> {t("annual_price_card") + "*"} </p>
                </div>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_view_company")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_b2b")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  
                  {t("subsription_basic_showcase")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <CheckOut className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso-checkout">
                  
                  {t("subscription_advanced_showcas")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <CheckOut className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso-checkout">
                  
                  {t("subscription_business_matching_score")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <CheckOut className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso-checkout">
                  {t("subscription_view_products_showcase")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <CheckOut className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso-checkout">
                  
                  {t("subscription_direct_contact")}
                </span>
              </div>
            </Col>

            <Col
              xxl={{ span: 7, offset: 1 }}
              xl={{ span: 7, offset: 1 }}
              className="col-card-pricing"
              style={{
                backgroundColor: "#bf3f48",
                border: "2px solid #bf3f48",
              }}
            >
              <div justify="center" style={{ color: "#fff" }}>
                <div>
                  <p className="titles_card">
                    
                    <b>Business</b>
                  </p>
                  <hr
                    style={{
                      width: "80px",
                      height: "2px",
                      borderWidth: "0",
                      color: "gray",
                      backgroundColor: "#fff",
                    }}
                  ></hr>
                  <p className="p-price-card">
                    
                    <b>€490</b>
                  </p>
                  <p> {t("annual_price_card") + "*"} </p>
                </div>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_view_company")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_b2b")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  
                  {t("subsription_basic_showcase")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  
                  {t("subscription_advanced_showcas")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  
                  {t("subscription_business_matching_score")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_view_products_showcase")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <CheckOut className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso-checkout">
                  
                  {t("subscription_direct_contact")}
                </span>
              </div>
            </Col>

            <Col
              xxl={{ span: 7, offset: 1 }}
              xl={{ span: 7, offset: 1 }}
              className="col-card-pricing"
              style={{
                backgroundColor: "#be1522",
                border: "2px solid #be1522",
              }}
            >
              <div justify="center" style={{ color: "#fff" }}>
                <div>
                  <p className="titles_card">
                    
                    <b>Executive</b>
                  </p>
                  <hr
                    style={{
                      width: "80px",
                      height: "2px",
                      borderWidth: "0",
                      color: "gray",
                      backgroundColor: "#fff",
                    }}
                  ></hr>
                  <p className="p-price-card">
                    
                    <b>€590</b>
                  </p>
                  <p>{t("annual_price_card") + "*"} </p>
                </div>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_view_company")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_b2b")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  
                  {t("subsription_basic_showcase")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  
                  {t("subscription_advanced_showcas")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  
                  {t("subscription_business_matching_score")}
                </span>
              </div>
              <div className="row-pricing-white">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  {t("subscription_view_products_showcase")}
                </span>
              </div>
              <div className="row-pricing-grey">
                <div className="div-check-checkout">
                  <Check className="icon-check-checkout" />
                </div>
                <span className="text-piani-accesso">
                  
                  {t("subscription_direct_contact")}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        */}

        <div style={{ textAlign: "center" }}>
          <p style={{ marginTop: "20px" }}>
            {"*" + t("subscription_foreign_adoption")}
          </p>
        </div>
      </div>
    </>
  );
}
export default Pricing;
