import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import {
  Collapse,
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import vietnamFlag from "../image/flags/vietnam.png";
import singaporeFlag from "../image/flags/singapore.png";
import japanFlag from "../image/flags/japan.png";
import uzbekistanFlag from "../image/flags/uzbekistan.png";
import usaFlag from "../image/flags/usa.png";
import qatarFlag from "../image/flags/qatar.png";
import arabEmiratesFlag from "../image/flags/arab-emirates.png";
import "./Contacts.css";
import expoClient from "../expoClient";
import logoWhite from "../image/logo-536x121-white.png";
import logoIcham from "../image/icham.png";
import { ReactComponent as Position } from "../icon/map-position.svg";
import { ReactComponent as Phone } from "../icon/phone.svg";
import { ReactComponent as Mail } from "../icon/mail.svg";
import CardChamber from "../components/CardChamber";
const { Panel } = Collapse;

const layout = {
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function Contacts() {
  ReactGA.pageview(window.location.pathname + window.location.search);

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [form] = Form.useForm();
  const onFinish = async (values) => {
    try {
      let res = await expoClient.contact.post(values);
      if (typeof res !== "undefined") {
        form.resetFields();
        message.success(t("contact_success"));
      }
    } catch (error) {
      message.error("Errore di sistema.");
      // const msg = _.capitalize(t('error'));
      // const desc = t('signup_error_post');
      // notification.error({
      //     key: 'postRegistrationError',
      //     message: msg,
      //     description: desc,
      //     placement: 'topRight',
      //     duration: 0
      // });
    } finally {
      //  this.setState({ submittingData: false });
    }
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkValues = (changed, values) => {
    let disable = false;

    if (!values["privacy"]) {
      disable = true;
    }

    setDisableSubmit(disable);
  };

  const { t } = useTranslation();
  return (
    <div style={{ backgroundColor: "#efefef" }}>
      <Row className="sezione_banner_contact" justify="center">
        {/* <img className="logo_white" src={logoWhite} alt="logo" /> */}
      </Row>
      <Row justify="center">
        <h2 className="titles_black ">{t("where_active")}</h2>
      </Row>
      <CardChamber />
      {/* <Row className="sezione_grey ">
        <Col span={24} className="col_center">
          <Row justify="center">
            <h1 className="titles">{t("waiting_countries")}</h1>
          </Row>
          <Row className="contain_countries">
            <Col
              xl={{ span: 4 }}
              lg={{ span: 4 }}
              md={{ span: 5 }}
              xs={{ span: 24 }}
              className="col_center"
            >
              <Row justify="center">
                <img
                  className="country_flag"
                  src={singaporeFlag}
                  alt="singapore"
                />
              </Row>
              <Row justify="center">
                <h2 className="country_name">{t("country_SGP")}</h2>
              </Row>
            </Col>
            <Col
              xl={{ span: 4 }}
              lg={{ span: 4 }}
              md={{ span: 5 }}
              xs={{ span: 24 }}
              className="col_center"
            >
              <Row justify="center">
                <img className="country_flag" src={japanFlag} alt="japan" />
              </Row>
              <Row justify="center">
                <h2 className="country_name">{t("country_JPN")}</h2>
              </Row>
            </Col>
            <Col
              xl={{ span: 4 }}
              lg={{ span: 4 }}
              md={{ span: 5 }}
              xs={{ span: 24 }}
              className="col_center"
            >
              <Row justify="center">
                <img className="country_flag" src={usaFlag} alt="usa" />
              </Row>
              <Row justify="center">
                <h2 className="country_name">{t("country_USA")}</h2>
              </Row>
            </Col>
            <Col
              xl={{ span: 4 }}
              lg={{ span: 4 }}
              md={{ span: 5 }}
              xs={{ span: 24 }}
              className="col_center"
            >
              <Row justify="center">
                <img
                  className="country_flag"
                  src={uzbekistanFlag}
                  alt="uzbekistan"
                />
              </Row>
              <Row justify="center">
                <h2 className="country_name">{t("country_UZB")}</h2>
              </Row>
            </Col>
            <Col
              xl={{ span: 4 }}
              lg={{ span: 4 }}
              md={{ span: 5 }}
              xs={{ span: 24 }}
              className="col_center"
            >
              <Row justify="center">
                <img className="country_flag" src={qatarFlag} alt="qatar" />
              </Row>
              <Row justify="center">
                <h2 className="country_name">{t("country_QAT")}</h2>
              </Row>
            </Col>
            <Col
              xl={{ span: 4 }}
              lg={{ span: 4 }}
              md={{ span: 5 }}
              xs={{ span: 24 }}
              className="col_center"
            >
              <Row justify="center">
                <img
                  className="country_flag"
                  src={arabEmiratesFlag}
                  alt="arab emirates"
                />
              </Row>
              <Row justify="center">
                <h2 className="country_name">{t("country_ARE")}</h2>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row> */}
      <Row justify="center">
        <h2 className="titles_black ">{t("ask_informations")}</h2>
      </Row>
      <Row justify="center" className="sezione_form">
        <Col
          xxl={{ span: 14 }}
          md={{ span: 18 }}
          xs={{ span: 24 }}
          className="col_center sezione_form_white"
        >
          <Row>
            <Col>
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={checkValues}
                form={form}
              >
                <Row gutter={8} justify="center">
                  <Col xl={{ span: 11 }} xs={{ span: 24 }}>
                    <Form.Item
                      className="form_item"
                      name="name"
                      rules={[
                        { required: true, message: t("form_field_required") },
                      ]}
                    >
                      <Input placeholder={t("form_name") + "*"} />
                    </Form.Item>
                    <Form.Item
                      className="form_item"
                      name="surname"
                      rules={[
                        { required: true, message: t("form_field_required") },
                      ]}
                    >
                      <Input placeholder={t("form_surname") + "*"} />
                    </Form.Item>
                    <Form.Item
                      className="form_item"
                      name="email"
                      rules={[
                        { required: true, message: t("form_field_required") },
                      ]}
                    >
                      <Input placeholder={t("form_email") + "*"} />
                    </Form.Item>
                    <Form.Item
                      className="form_item"
                      name="phone"
                      rules={[{ required: false }]}
                    >
                      <Input placeholder={t("form_telephone")} />
                    </Form.Item>
                    <Form.Item
                      className="form_item"
                      name="country"
                      rules={[{ required: false }]}
                    >
                      <Input placeholder={t("form_country")} />
                    </Form.Item>
                  </Col>
                  <Col
                    xl={{ span: 11, offset: 1 }}
                    xs={{ span: 24 }}
                    className="col_left"
                  >
                    <Form.Item
                      className="form_item"
                      name="note"
                      rules={[{ required: false }]}
                    >
                      <Input.TextArea
                        placeholder={t("form_note")}
                        rows={6}
                        className="form_note"
                      />
                    </Form.Item>
                    <h3 style={{ marginBottom: "0px" }}>
                      {t("privacy_title")}
                    </h3>
                    {t("privacy_text")}
                    <Form.Item
                      name="privacy"
                      required="true"
                      valuePropName="checked"
                      className="form_privacy"
                    >
                      <Checkbox className="checkbox-form">
                        {t("form_i_agree")}
                      </Checkbox>
                    </Form.Item>
                    {t("privacy_policy_link")}
                    <Link
                      className="scrollToTop link-privacy"
                      to="/privacypolicy"
                    >
                      {" "}
                      <b> {t("privacy_policy")} </b>
                    </Link>
                  </Col>
                </Row>
                <Col span="12"></Col>

                <Form.Item>
                  <Button
                    type="primary"
                    disabled={disableSubmit}
                    htmlType="submit"
                    className="button-read-more"
                  >
                    {t("form_submit")}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify="center" className="sezione_form_contact">
        <Col
          xxl={{ span: 14 }}
          md={{ span: 18 }}
          xs={{ span: 24 }}
          className="col_center "
        >
          <Row justify="center">
            <h2 className="titles_black_contact">{t("form_contact_us")}</h2>
          </Row>
          <Row justify="center">
            {/*<Col xl={{ span: 10, offset:1 }} xs={{ span: 20 }} md={{}} align="left">
              <h2 className="important_text">{t("promotion")}</h2>
            </Col>*/}
            <Col
              xl={{ span: 10, offset: 2 }}
              xs={{ span: 20 }}
              align="left"
              style={{ fontSize: "20px" }}
            >
              <p className="p-contact-address">
                {" "}
                <Position className="icon_red" /> {t("address_spx")}{" "}
              </p>
              <p className="p-contact-address">
                {" "}
                <Phone className="icon_red" /> {t("phone_spx")}{" "}
              </p>
              <p className="p-contact-address">
                {" "}
                <Mail className="icon_red" /> {t("mail_spx")}{" "}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
export default Contacts;
