import React from "react";
import { Row, Col, Button, } from "antd";
import { useTranslation } from "react-i18next";
import { Parallax } from 'react-parallax';

import Values from '../image/our-values.png';

import "./ProductSpx.css";

function ProductSpx() {
    const { t } = useTranslation();



    return (

        <div>
            <Parallax className="product-spx"
                blur={0}
                bgImage={require('../image/banner.png')}
                bgImageAlt="smartmockups"
                strength={700}
            >
                <div className="mbr-overlay-spx">
                   
                        <Row>

                            <Col xl={{span:12}} style={{display:'flex', alignItems:'center'}}>
                                <img src={Values} width="100%" />
                            </Col>
                            <Col xl={{span:12}}>
                                <p className="p-product-spx"
                dangerouslySetInnerHTML={{
                    __html: t("text_product_spx", {
                        interpolation: { escapeValue: false },
                    }),
                }}
            ></p>
                            </Col>
                        </Row>
                   
                </div>
            </Parallax>
        </div>

    );
}
export default ProductSpx;
