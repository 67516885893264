import React from "react";
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import Faq from "./pages/Faq";
import Contacts from "./pages/Contacts";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Pricing from "./pages/Pricing";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  ReactGA.initialize('UA-52437112-21');
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/faq">
          <Faq />
        </Route>
        <Route path="/contacts">
          <Contacts />
        </Route>
        <Route path="/privacypolicy">
          <PrivacyPolicy />
        </Route>
        <Route path="/pricing">
          <Pricing />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}
export default App;
