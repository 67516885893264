import React from "react";
import { Button } from "antd";
import ImageGallery from "react-image-gallery";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";

import { useTranslation } from 'react-i18next';

import "./SliderCarousel.css";

function SliderCarousel() {

  const { t } = useTranslation();

  const images = [
    {
      original: require("../image/slide1.png"),
      title: t("title_spot1"),
      subtitle: t("subtitle_spot1"),
      bulletClass: "carouselBullets",
    },
    {
      original: require("../image/slide2.jpg"),
      title: t("title_spot2"),
      subtitle: t("subtitle_spot2"),
      bulletClass: "carouselBullets",
    },
    {
      original: require("../image/slide3.jpg"),
      title: t("title_spot3"),
      subtitle: t("subtitle_spot3"),
      bulletClass: "carouselBullets",
    },
  ];

  const leftButton = (onClick, disabled) => {
    return (
      <Button
        shape="circle"
        className="carousel-arrow-left"
        onClick={onClick}
        disabled={disabled}
      >
        <LeftOutlined className="arrows-ant-carousel-spot"></LeftOutlined>
      </Button>
    );
  };

  const rightButton = (onClick, disabled) => {
    return (
      <Button
        shape="circle"
        className="carousel-arrow-right"
        onClick={onClick}
        disabled={disabled}
      >
        <RightOutlined className="arrows-ant-carousel-spot"></RightOutlined>
      </Button>
    );
  };

  const renderImage = (item) => {
    return (
      <div
        style={{
          background: `url(${item.original}) rgba(0, 0, 0, 0.6)`,
          backgroundBlendMode: "multiply",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="carousel-spot"
         style={{ display: "flex",
         flexDirection: "column",
         justifyContent: "center",
         alignItems: "center",}}
        >
          <div className="carousel-text" style={{whiteSpace: 'break-spaces', textAlign:'left'}}>
            <div className="text-slider">{item.title}</div>
            <div className="subtitle-slider">{item.subtitle}</div>
          </div>
        </div>
      </div>
    );
  };



  return (
    <>
      <ImageGallery
        items={images}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
        showBullets={true}
        renderLeftNav={leftButton}
        renderRightNav={rightButton}
        renderItem={renderImage}
      />
     
    </>
  );
}
export default SliderCarousel;
