import React from "react";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import miro from "../image/miro.jpg";

import "./ProgressBar.css";

function ProgressBar() {
  const { t } = useTranslation();

  return (
    <>
      <Row align="middle">
        <Col xl={{ span: 12 }} xs={{ span: 24 }}>
          <div className="contain_progress_bar">
            <h1 className="title_progress_bar"> {t("title_progress_bar")} </h1>
            <p
              className="subtitle_progress_bar"
              dangerouslySetInnerHTML={{
                __html: t("paragraph1_progress_bar", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></p>
          </div>
        </Col>
        <Col xl={{ span: 12 }} xs={{ span: 24 }}>
          <img width="100%" src={miro} alt="miro" />
        </Col>
      </Row>
    </>
  );
}
export default ProgressBar;
