import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import translationEng from "./locales/en/translation.json";
import translationIta from "./locales/it/translation.json";

i18n
  //.use(detector)
  .use(initReactI18next)
  .init({
    resources: {
      "en-EN": {
        translations: translationEng,
      },
      "en-US": {
        translations: translationEng,
      },
      "it-IT": {
        translations: translationIta,
      },
    },
    debug: true,
    fallbackLng: "it-IT", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
  });
export default i18n;
